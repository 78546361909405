<template>
	<div class="column is-9-tablet is-6-desktop">
		<div
			v-if="type === 'business'"
			class="category is-block is-relative"
			@click="emit(category.titlu)"
		>
			<div class="category__content is-relative">
				<img
					:src="`${databaseLink}${category.imagine.url}`"
					:alt="category.titlu"
				/>
			</div>
			<h3
				class="category__title is-flex is-align-items-center is-justify-content-center has-text-centered py-3 px-3"
			>
				{{ category.titlu }}
			</h3>
			<div
				class="category__overlay is-flex is-hidden-touch is-align-items-center is-justify-content-center"
			>
				<ion-icon name="document-text-outline"></ion-icon>
			</div>
		</div>
		<router-link
			v-else
			:to="
				$route.path === '/'
					? `${formatCategoryTitle}/`
					: `${$route.path}${formatCategoryTitle}/`
			"
			class="category is-block is-relative"
		>
			<div class="category__content is-relative">
				<img
					:src="`${databaseLink}${category.imagine.url}`"
					:alt="category.titlu"
				/>
			</div>
			<h3
				v-if="type === 'subcategory'"
				class="category__title py-3 px-3 has-text-centered-touch"
			>
				{{ category.titlu }}
			</h3>
			<h3
				v-else
				class="category__title py-3 px-3 has-text-centered-touch"
			>
				{{ category.titlu_home }}
			</h3>
			<div
				class="category__overlay is-flex is-hidden-touch is-align-items-center is-justify-content-center"
			>
				<ion-icon name="link-outline"></ion-icon>
			</div>
		</router-link>
	</div>
</template>

<script>
	export default {
		props: {
			category: {
				type: Object,
				required: true,
			},
			type: String,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			formatCategoryTitle() {
				let str = this.category.titlu.toLowerCase();
				let charMap = {
					ă: 'a',
					â: 'a',
					î: 'i',
					ș: 's',
					ț: 't',
				};
				let rx = /(ă|â|î|ș|ț)/g;
				if (rx.test(str)) {
					str = str.replace(rx, function(m, key) {
						return charMap[key];
					});
				}
				str = str.replace(/[^a-z\d\s-]/gi, '');
				str = str.replace(/[\s-]+/g, ' ');
				str = str.replace(/\s/g, '-');
				return str;
			},
		},
		methods: {
			emit(value) {
				this.$emit('open', value);
			},
		},
	};
</script>

<style lang="scss">
	.category {
		&:hover {
			.category__overlay {
				opacity: 1;
			}
			.category__title {
				color: $secondary;
				@media (min-width: 1023px) {
					color: $white;
				}
			}
		}
		&__content {
			background-color: $primary;
			border-radius: $round-corners;
			padding-top: 56.25%;
			overflow: hidden;
		}
		&__title {
			background-color: $white;
			color: $black;
			font-size: 1.125rem;
			font-weight: $semibold;
			line-height: 1.5;
			margin: 0 auto;
			text-transform: uppercase;
			transform: translateY(-25%);
			transition: color 0.3s ease;
			z-index: 1;
			width: 80%;
			@media (min-width: 576px) {
				font-size: 1.25rem;
			}
			@media (min-width: 1024px) {
				background-color: rgba(0, 0, 0, 0.4);
				color: $white;
				position: absolute;
				bottom: 0;
				left: 0;
				text-align: center;
				transform: translateY(0);
				width: 100%;
			}
		}
		&__overlay {
			background-color: rgba(0, 0, 0, 0.3);
			color: $white;
			font-size: 3rem;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: opacity 0.3s ease-in-out;
		}
		img {
			border-radius: $round-corners;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
	}
</style>
