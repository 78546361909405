<template>
	<nav class="navbar is-relative is-block" v-if="fbo">
		<div
			class="navbar__top"
			:style="{
				backgroundImage:
					'url(' + require('@/assets/header-bg.png') + ')',
			}"
		>
			<section class="section py-2">
				<div class="container">
					<div
						class="forever is-flex is-justify-content-space-between is-align-items-center"
					>
						<div class="forever__info">
							<h2>{{ fbo.brand }}</h2>
							<h4>{{ fbo.brand_titlu }}</h4>
							<ul>
								<li v-for="info in contactInfos" :key="info.id">
									<a :href="info.url">{{ info.titlu }}</a>
								</li>
							</ul>
						</div>
						<div
							class="forever__owner is-inline-flex is-align-items-center"
						>
							<router-link to="/" class="avatar mr-3"
								><img
									:src="`${databaseLink}/${fbo.avatar.url}`"
									:alt="fbo.avatar.alternativeText"
							/></router-link>
							<div>
								<h2>{{ fbo.nume }}</h2>
								<h4>{{ fbo.nume_titlu }}</h4>
							</div>
						</div>
						<router-link class="forever__logo" to="/"
							><img
								height="130"
								:src="`${databaseLink}/${fbo.logo.url}`"
								:alt="fbo.logo.alternativeText"
						/></router-link>
					</div>
				</div>
			</section>
		</div>
		<!-- END TOP SECTION -->
		<div class="navbar__bottom is-relative">
			<section class="section py-0">
				<div class="container">
					<div
						class="forever is-flex is-align-items-center is-justify-content-space-between"
					>
						<div
							class="forever__info is-flex is-align-items-center"
						>
							<router-link to="/" class="avatar mr-3"
								><img
									width="70"
									height="70"
									:src="`${databaseLink}/${fbo.avatar.url}`"
									:alt="fbo.avatar.alternativeText"
							/></router-link>
							<div style="width: calc(100% - 82px)">
								<h2>{{ fbo.nume }}</h2>
								<h4>{{ fbo.nume_titlu }}</h4>
							</div>
						</div>
						<div
							class="menu is-flex is-flex-direction-column is-justify-content-space-between"
							:class="{ 'is-open': isClosed }"
						>
							<div
								class="menu__info pt-5 pb-3 has-text-centered is-relative"
								style="height: 137px"
							>
								<h2>{{ fbo.brand }}</h2>
								<h4>{{ fbo.brand_titlu }}</h4>
								<ul>
									<li
										v-for="info in contactInfos"
										:key="info.id"
									>
										<a :href="info.url">{{ info.titlu }}</a>
									</li>
								</ul>
								<button
									class="btn btn--close"
									@click="closeMenu"
								>
									<ion-icon name="close-sharp"></ion-icon>
								</button>
							</div>
							<ul
								class="menu__inner"
								:style="[
									windowWidth < 991
										? { height: `calc(100% - 252px)` }
										: { height: 'auto' },
								]"
							>
								<li v-for="link in headerLinks" :key="link.id">
									<router-link :to="`${link.url}/`">{{
										link.titlu
									}}</router-link>
								</li>
							</ul>
							<router-link class="menu__logo py-3 is-block" to="/"
								><img
									height="90"
									:src="`${databaseLink}/${fbo.logo.url}`"
									:alt="fbo.logo.alternativeText"
							/></router-link>
						</div>
						<button
							class="btn btn--toggle is-relative is-clickable"
							@click="openMenu"
						>
							<span class="inner"></span>
						</button>
					</div>
				</div>
			</section>
		</div>
		<!-- END BOTTOM SECTION -->
	</nav>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		props: {
			isClosed: Boolean,
		},
		name: 'Menu',
		data() {
			return {
				isOpen: false,
				windowWidth: window.innerWidth,
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('general', ['fbo', 'headerLinks', 'contactInfos']),
		},
		methods: {
			openMenu() {
				this.isOpen = true;
				this.$emit('open-menu', this.isOpen);
			},
			closeMenu() {
				this.$emit('close-menu', false);
			},
		},
		watch: {
			$route() {
				this.isOpen = false;
			},
		},
	};
</script>

<style scoped lang="scss">
	.navbar {
		z-index: 9;
		&__top {
			display: none;
			background-color: $primary;
			background-size: cover;
			background-position: center;
			.forever {
				color: $white;
				text-align: center;
				h2 {
					line-height: 1.5;
					font-weight: $semibold;
					font-size: 1.5rem;
					text-transform: uppercase;
				}
				h4 {
					font-weight: $medium;
					font-size: 1rem;
					line-height: 1.5;
					text-transform: uppercase;
				}
				a {
					color: rgba($white, 0.9);
					font-size: 1rem;
					font-weight: $medium;
					&:hover {
						color: $white;
					}
				}
				img {
					height: 130px;
					width: auto;
				}
				&__owner {
					.avatar {
						background-color: $primary;
						border-radius: 50%;
						width: 150px;
						height: 150px;
					}
					img {
						border-radius: 50%;
						height: inherit;
						width: inherit;
					}
				}
			}
			@media (min-width: 992px) {
				display: block;
			}
		}
		&__bottom {
			box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
			background-color: $white;
			.forever {
				h2 {
					line-height: 1.5;
					font-weight: $semibold;
					font-size: 1.25rem;
					text-transform: uppercase;
				}
				h4 {
					font-weight: $medium;
					font-size: 1rem;
					line-height: 1;
				}
				&__info {
					.avatar {
						border-radius: 50%;
						height: 70px;
						width: 70px;
						img {
							border-radius: inherit;
							height: inherit;
							width: inherit;
						}
					}
					@media (min-width: 992px) {
						display: none !important;
					}
				}
			}
			.btn {
				&--close {
					background: none;
					border-radius: 0;
					border: 0;
					color: rgba($white, 0.9);
					position: absolute;
					top: 0;
					right: 0;
					height: 40px;
					width: 40px;
					&:hover {
						color: $white;
					}
					&:focus {
						outline: 1px dotted $white;
						outline-offset: 0;
					}
					@media (min-width: 992px) {
						display: none;
					}
				}
				&--toggle {
					box-sizing: content-box;
					background: none;
					border: 0;
					cursor: pointer;
					display: block;
					padding: 10px 0;
					width: 40px;
					height: 60px;
					&:hover {
						.inner {
							background-color: $primary;
							box-shadow: 0 -10px 0 0px $primary,
								0 10px 0 0px $primary;
						}
					}
					.inner {
						background-color: rgba($primary, 0.8);
						box-shadow: 0 -10px 0 0px rgba($primary, 0.8),
							0 10px 0 0px rgba($primary, 0.8);
						border-radius: $round-corners;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						transition: background-color 0.3s ease-in-out,
							box-shadow 0.3s ease-in-out;
						height: 4px;
						width: 40px;
					}
					@media (min-width: 992px) {
						display: none;
					}
				}
			}
			.menu {
				background-color: $white;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				transform: translateX(100%);
				transition: transform 0.3s ease;
				width: 300px;
				z-index: 99999;
				@media (min-width: 992px) {
					background: none;
					position: initial;
					transform: translateX(0);
					width: auto;
					margin-left: auto;
				}
				&.is-open {
					transform: translateX(0);
				}
				&__info {
					background-color: $primary;
					color: $white;
					h2 {
						font-size: 1.25rem;
						line-height: 1.5;
					}
					h4 {
						font-size: 1rem;
						font-weight: $medium;
						line-height: 1.5;
						text-transform: uppercase;
					}
					a {
						color: rgba($white, 0.9);
						font-size: 0.875rem;
						&:hover {
							color: $white;
						}
					}
					@media (min-width: 992px) {
						display: none;
					}
				}
				&__inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					overflow-y: auto;
					height: 100%;
					@media (min-width: 992px) {
						flex-direction: row;
					}
					a {
						color: $dark;
						display: block;
						font-size: 0.9375rem;
						font-weight: $medium;
						overflow: hidden;
						padding: 1rem 0.5rem;
						position: relative;
						text-transform: uppercase;
						text-align: center;
						&::before {
							content: '';
							background-color: $secondary;
							display: block;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%) translateX(3px);
							transition: transform 0.3s ease-in-out;
							height: 50%;
							width: 3px;
						}
						&:hover {
							color: $secondary;
							&::before {
								transform: translateY(-50%) translateX(0);
							}
						}
						&.router-link-exact-active {
							color: $secondary;
							&::before {
								transform: translateY(-50%) translateX(0);
							}
						}
						@media (min-width: 992px) {
							padding: 20px 0.75rem;
							&::before {
								top: inherit;
								bottom: 0;
								left: 50%;
								transform: translateX(-50%) translateY(3px);
								transition: transform 0.3s ease-in-out;
								height: 3px;
								width: 50%;
							}
							&:hover {
								&::before {
									transform: translateX(-50%) translateY(0);
								}
							}
							&.router-link-exact-active {
								color: $secondary;
								&::before {
									transform: translateY(0) translateX(-50%);
								}
							}
						}
					}
				}
				&__logo {
					background-color: $primary;
					text-align: center;
					height: 115px;
					img {
						height: 90px;
					}
					@media (min-width: 992px) {
						display: none !important;
					}
				}
			}
		}
	}
</style>
