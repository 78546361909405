var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swiper',{staticClass:"primary-banner",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.slides),function(slide){return _c('swiper-slide',{key:slide.id},[_c('div',{staticClass:"slide__image is-relative"},[_c('img',{attrs:{"src":_vm.width > 1000
						? (_vm.databaseLink + "/" + (slide.imagine[0].url))
						: _vm.width <= 1000 && _vm.width > 750
						? (_vm.databaseLink + "/" + (slide.imagine[0].formats.large.url))
						: _vm.width <= 750 && _vm.width > 500
						? (_vm.databaseLink + "/" + (slide.imagine[0].formats.medium.url))
						: _vm.width <= 500
						? (_vm.databaseLink + "/" + (slide.imagine[0].formats.small.url))
						: null,"alt":""}})])])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }