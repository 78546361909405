import axios from 'axios';

const state = {
	fbo: null,
	headerLinks: [],
	footerLinks: [],
	contactInfos: [],
	homeBannerCarouselSlides: [],
	homeAboutUsSlides: [],
	homeMotto: [],
	carouselTestimonial: [],
	categories: [],
	certificates: [],
	products: [],
	articles: [],
	audio: [],
	submitedForm: null,
	loading: false
};

const mutations = {
	SET_FBO(state, payload) {
		state.fbo = payload;
	},
	SET_HEADER_LINKS(state, payload) {
		state.headerLinks = payload;
	},
	SET_FOOTER_LINKS(state, payload) {
		state.footerLinks = payload;
	},
	SET_CONTACT_INFOS(state, payload) {
		state.contactInfos = payload;
	},
	SET_HOME_BANNER_CAROUSEL(state, payload) {
		state.homeBannerCarouselSlides = payload;
	},
	SET_HOME_ABOUT_US_CAROUSEL(state, payload) {
		state.homeAboutUsSlides = payload;
	},
	SET_HOME_MOTTO(state, payload) {
		state.homeMotto = payload;
	},
	SET_CAROUSEL_TESTIMONIAL(state, payload) {
		state.carouselTestimonial = payload;
	},
	SET_CATEGORIES(state, payload) {
		state.categories = payload;
	},
	SET_CERTIFICATES(state, payload) {
		state.certificates = payload;
	},
	SET_PRODUCTS(state, payload) {
		state.products = payload;
	},
	SET_ARTICLES(state, payload) {
		state.articles = payload;
	},
	SET_AUDIO(state, payload) {
		state.audio = `${process.env.VUE_APP_STRAPI_DATABASE_URL}/${payload.audio.url}`;
	},
	SET_FORM_MSG_AFTER_SUBMITED(state, payload) {
		state.submitedForm = {
			status: payload.status,
			msg: payload.msg
		}
		
		setTimeout(()=> {
			state.submitedForm = null
		}, 5000)
	},
	SET_LOADING(state, payload) {
		state.loading = payload
		setTimeout(()=> {
			state.loading = false
		}, 3000)
	}
};

const actions = {
	getGeneralData({ commit }) {
		const lang = localStorage.getItem('lang');
		const location = localStorage.getItem('location');

		// console.log(location === "RO" &&
		// (location === 'RO' && lang === null) ||
		// (location !== 'RO' && lang === null) ||
		// (location === 'RO' && (lang !== null && lang === 'RO')) ||
		// (location !== 'RO' && (lang !== null && lang === 'RO')))
		
		if(location === "RO" &&
		(location === 'RO' && lang === null) ||
		(location !== 'RO' && lang === null) ||
		(location === 'RO' && (lang !== null && lang === 'RO')) ||
		(location !== 'RO' && (lang !== null && lang === 'RO'))){
			console.log('romanian');
		axios
			.all([
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/fbo`),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HeaderLinks`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=FooterLinks`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=ContactInfos`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeBannerCarousel`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeAboutUsCarousel`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeMotto`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeTestimonialCarousel`
				),
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/categories?_sort=pozitie:ASC`),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/certificates`
				),
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/products?highlight_produs=true`),
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/articles?highlight_articol=true`),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=WebsiteMusic`
				),
			])
			.then(
				axios.spread(
					(
						fbo,
						headerLinks,
						footerLinks,
						contactInfos,
						homeBannerCarousel,
						homeAboutUsCarousel,
						homeMotto,
						homeTestimonialCarousel,
						categories,
						certificates,
						products,
						articles,
						audio
					) => {
						commit('SET_FBO', fbo.data);
						commit('SET_HEADER_LINKS', headerLinks.data[0].link);
						commit('SET_FOOTER_LINKS', footerLinks.data[0].link);
						commit('SET_CONTACT_INFOS', contactInfos.data[0].info);
						commit(
							'SET_HOME_BANNER_CAROUSEL',
							homeBannerCarousel.data[0].carousel
						);
						commit(
							'SET_HOME_ABOUT_US_CAROUSEL',
							homeAboutUsCarousel.data[0].carousel
						);
						commit('SET_HOME_MOTTO', homeMotto.data[0].motto);
						commit(
							'SET_CAROUSEL_TESTIMONIAL',
							homeTestimonialCarousel.data[0].carousel
						);
						commit('SET_CATEGORIES', categories.data);
						commit('SET_CERTIFICATES', certificates.data);
						commit('SET_PRODUCTS', products.data);
						commit('SET_ARTICLES', articles.data);
						commit('SET_AUDIO', audio.data[0].audio);

					}
				),
				commit('SET_LOADING', true)
			);
		} else if(location !== "RO" &&
		(location !== 'RO' && lang === null) ||
		(location === 'RO' && (lang !== null && lang === 'EN')) ||
		(location !== 'RO' && (lang !== null && lang === 'RO'))) {
			console.log('english');
			axios
			.all([
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/fbo`),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HeaderLinks`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=FooterLinks`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=ContactInfos`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeBannerCarousel`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeAboutUsCarousel`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeMotto`
				),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=HomeTestimonialCarousel`
				),
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/categories?_sort=pozitie:ASC&_locale=en`),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/certificates`
				),
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/products?highlight_produs=true`),
				axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/articles?highlight_articol=true&_locale=en`),
				axios.get(
					`${process.env.VUE_APP_STRAPI_DATABASE_URL}/lists?tip=WebsiteMusic`
				),
			])
			.then(
				axios.spread(
					(
						fbo,
						headerLinks,
						footerLinks,
						contactInfos,
						homeBannerCarousel,
						homeAboutUsCarousel,
						homeMotto,
						homeTestimonialCarousel,
						categories,
						certificates,
						products,
						articles,
						audio
					) => {
						commit('SET_FBO', fbo.data);
						commit('SET_HEADER_LINKS', headerLinks.data[0].link);
						commit('SET_FOOTER_LINKS', footerLinks.data[0].link);
						commit('SET_CONTACT_INFOS', contactInfos.data[0].info);
						commit(
							'SET_HOME_BANNER_CAROUSEL',
							homeBannerCarousel.data[0].carousel
						);
						commit(
							'SET_HOME_ABOUT_US_CAROUSEL',
							homeAboutUsCarousel.data[0].carousel
						);
						commit('SET_HOME_MOTTO', homeMotto.data[0].motto);
						commit(
							'SET_CAROUSEL_TESTIMONIAL',
							homeTestimonialCarousel.data[0].carousel
						);
						commit('SET_CATEGORIES', categories.data);
						commit('SET_CERTIFICATES', certificates.data);
						commit('SET_PRODUCTS', products.data);
						commit('SET_ARTICLES', articles.data);
						commit('SET_AUDIO', audio.data[0].audio);

					}
				),
				commit('SET_LOADING', true)
			);
		}
	},
};

const getters = {
	getCategoryByName: (state) => (routeName) => {
		return state.categories.filter(
			(category) => category.nume === routeName
		);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};

