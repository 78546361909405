<template>
	<footer class="footer py-5">
		<section class="section py-0">
			<div class="container">
				<div class="columns is-flex-tablet">
					<div class="column is-4 ">
						<h2
							class="is-relative pb-2 mb-6 has-text-center has-text-left-tablet"
						>
							Contactați-ne
						</h2>
						<ul class="details">
							<li
								class="is-flex mb-3"
								v-for="info in contactInfos"
								:key="info.id"
							>
								<div class="icon mr-3">
									<ion-icon :name="info.simbol"></ion-icon>
								</div>
								<p v-if="info.simbol === 'location-outline'">
									{{ info.titlu }}
								</p>
								<a
									v-if="info.simbol !== 'location-outline'"
									:href="info.url"
									>{{ info.titlu }}</a
								>
							</li>
						</ul>
					</div>
					<div class="column is-8 ">
						<h2
							class="is-relative pb-2 mb-6 has-text-center has-text-left-tablet"
						>
							Află mai multe despre noi
						</h2>
						<ul class="useful-links columns is-multiline">
							<li
								class="column is-6 py-0 mb-0"
								v-for="link in footerLinks"
								:key="link.id"
							>
								<a
									v-if="link.simbol === 't&c'"
									:href="link.url"
									target="_blank"
									>{{ link.titlu }}</a
								>
								<router-link
									v-else
									class="pb-3 mb-3"
									:to="`${link.url}/`"
									>{{ link.titlu }}</router-link
								>
							</li>
							<li class="column is-12 py-0">
								<ul class="is-flex">
									<li>
										<a
											class="fb mr-1"
											href="https://www.facebook.com/Best-Business-Forever-461713137349651"
											target="_blank"
											><ion-icon
												name="logo-facebook"
											></ion-icon
										></a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				<div class="footer__bottom pt-5" v-if="fbo">
					<div class="columns is-vcentered">
						<div class="column is-8">
							<markdown-it-vue :content="fbo.disclaimer_text" />
						</div>
						<div class="column is-4 has-text-centered mb-0">
							<router-link to="/" class="is-inline-block">
								<img
									width="150"
									:src="
										`${databaseLink}/${fbo.disclaimer_logo.url}`
									"
									:alt="fbo.disclaimer_logo.alternativeText"
								/>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
	</footer>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		name: 'Footer',
		data() {
			return {};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('general', ['footerLinks', 'contactInfos', 'fbo']),
		},
	};
</script>

<style scoped lang="scss">
	.footer {
		background-color: lighten($gray, 20%);
		&__bottom {
			border-top: 1px solid lighten($gray, 10%);
		}
		.column {
			@media (max-width: 768px) {
				margin-bottom: 3rem;
			}
		}
		h2 {
			border-bottom: 1px solid rgba(gray, 0.2);
			text-transform: uppercase;
			font-size: 1.5rem;
			&::before {
				content: '';
				background-color: $secondary;
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 70%;
				height: 3px;
			}
			@media (max-width: 768px) {
				text-align: center;
				&::before {
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		.details {
			.icon {
				color: darken($gray, 35%);
				font-size: 1.5rem;
			}
		}
		.useful-links {
			> li {
				> a {
					border-bottom: 1px solid rgba(gray, 0.2);
					display: block;
				}
				&:last-child {
					a {
						color: $black;
						font-size: 1.75rem;
					}
					.fb {
						color: $facebook;
					}
					.yt {
						color: $youtube;
					}
				}
			}
		}
	}
</style>
