import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import Tinybox from "vue-tinybox";
Vue.component('Tinybox', Tinybox);

import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';
Vue.use(MarkdownItVue);

import moment from 'moment';
Vue.prototype.moment = moment;

import { Modal } from 'buefy';
Vue.use(Modal);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper);

import Vuebar from 'vuebar';
Vue.use(Vuebar);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

import Meta from 'vue-meta'
Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

import VueFbCustomerChat from 'vue-fb-customer-chat'
Vue.use(VueFbCustomerChat, {
  page_id: 461713137349651, //  change 'null' to your Facebook Page ID,
  theme_color: '#13cf13', // theme color in HEX
  locale: 'ro_RO', // default 'en_US'
})

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

router.beforeEach((to, from, next)=>{
  if(to.name) {
    store.commit('general/SET_LOADING', true)
  }
  next()
})
router.afterEach(()=>{
  store.commit('general/SET_LOADING', false)
})
