<template>
	<swiper :options="swiperOptions" class="primary-banner">
		<swiper-slide v-for="slide in slides" :key="slide.id">
			<div class="slide__image is-relative">
				<img
					:src="
						width > 1000
							? `${databaseLink}/${slide.imagine[0].url}`
							: width <= 1000 && width > 750
							? `${databaseLink}/${slide.imagine[0].formats.large.url}`
							: width <= 750 && width > 500
							? `${databaseLink}/${slide.imagine[0].formats.medium.url}`
							: width <= 500
							? `${databaseLink}/${slide.imagine[0].formats.small.url}`
							: null
					"
					alt=""
				/>
			</div>
		</swiper-slide>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>
	</swiper>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		props: {
			slides: Array,
			type: String,
		},
		data() {
			return {
				width: window.innerWidth,
				swiperOptions: {
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					effect: 'fade',
					slidesPerView: 1,
					grabCursor: true,
					loop: true,
					autoplay: {
						delay: 4000,
					},
					speed: 400,
					keyboard: {
						enabled: true,
					},
				},
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('general', ['homeBannerCarouselSlides']),
		},
	};
</script>

<style scoped lang="scss">
	.primary-banner {
		background: $primary;
		.slide {
			&__image {
				padding-top: 56.25%;
				overflow: hidden;
				img {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.swiper-button-next,
	.swiper-button-prev {
		background-color: rgba($white, 0.6);
		color: $primary;
		margin: 0;
		transform: translateY(-50%);
		transition: background-color 0.3s ease;
		width: 50px;
		height: 120px;
		&::after {
			font-size: 2rem;
		}
		&:hover {
			background-color: $white;
		}
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev {
		left: 0;
	}
</style>
